@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.filterBox {
  width: 240px;
  margin-bottom: 8px;
}

.cardStyling {
  :global {
    .ant-card-body {
      padding: 28px;
    }
    #searchWrapper {
      -webkit-box-sizing: unset;
      box-sizing: unset;
    }
    #searchWrapper .ant-layout,
    .ant-layout * {
      -webkit-box-sizing: inherit;
      box-sizing: inherit;
    }
  }
}

.buttonWrapper {
  height: 30px;
  button {
    border: none;
  }
}

.headerWrapper {
  padding-bottom: 20px;
  .modalTitle {
    color: @titleBlack;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 0;
  }
  .subTitle {
    font-size: 14px;
    color: @blackMedium60;
    font-weight: 400;
  }
}

.cancelButtonWrapper {
  margin-left: 20px;
}

.footerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  .modalFooterCancelButton {
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    height: 30px;
    margin-left: 16px;
    font-weight: 500;
    color: @brandAccentBlue;
  }
}

.pageTitle {
  font-size: 16px;
  color: #181818;
  font-weight: 500;
  letter-spacing: 0.06px;
  margin-bottom: 15px;
}

.detailsDiv {
  display: flex;
  justify-content: space-between;
}

.expandableDivCollapsed {
  height: 40px;
  overflow: hidden;
}

.expandableDivExpanded {
  height: auto;
}

.filterDiv {
  border-bottom: 1px @lightGrayMedium solid;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
  svg {
    padding-top: 0;
  }
  .addNewButton {
    width: auto;
    margin-bottom: 8px;
  }
}

.emptyText {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  img {
    height: 115px;
  }
  span {
    color: rgba(36, 45, 65, 0.5);
    font-size: 13px;
    padding-top: 8px;
    letter-spacing: 0.42px;
  }
}

.selectorButtons {
  display: flex;
  justify-content: space-between;
  overflow: auto;
  padding-top: 13px;
  padding-bottom: 24px;
}

.selectorDropdown {
  display: none;
}

.selectorButton {
  text-transform: capitalize;
  margin: 8px 4px;
  width: 100%;
}

.selectorButton:first-child {
  margin-left: 0;
  margin-right: 4px;
}

.selectorButton:last-child {
  margin-right: 0;
  margin-left: 4px;
}

.selectedSelectorButton {
  text-transform: capitalize;
  color: @brandAccentBlue;
  margin: 8px 4px;
  width: 100%;
  border: 1px solid @brandAccentBlue;
}

.selectedSelectorButton:first-child {
  margin-left: 0;
  margin-right: 4px;
}

.selectedSelectorButton:last-child {
  margin-right: 0;
  margin-left: 4px;
}

.generalButton {
  margin: 8px;
  width: 80px;
}

.statusReported {
  color: @red;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 8px;
  .tag {
    margin-left: 8px;
  }
}

.statusApproved {
  color: @green;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  margin-right: 8px;
  .tag {
    margin-left: 8px;
  }
}

.statusAbrasion {
  color: @amber;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 8px;
  .tag {
    margin-left: 8px;
  }
}

:global {
  .ant-badge-status-dot {
    width: 8px;
    height: 8px;
  }
}

.type {
  text-transform: capitalize;
}

.option {
  text-transform: capitalize;
  background-color: @white;
}

.dropdown {
  margin-left: 8px;
  width: 200px;
  height: 34px;
  text-transform: capitalize;
  :global {
    .ant-select-selection {
      border-radius: 2px;
      border: solid 1px @lightGrayMedium;
      height: 34px;
    }
    .ant-select-arrow {
      top: 60%;
    }
  }
}

.saveAndFinishButton {
  width: 157px;
  height: 40px;
  opacity: 0.4;
  border-radius: 2px;
  background-color: @brandAccentBlue;
}

.saveAndFinishClickable {
  width: 157px;
  height: 40px;
  border-radius: 2px;
  background-color: @brandAccentBlue;
}

.cancelButton {
  width: 99px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px @lightGrayMedium;
}

.subMenu {
  text-transform: capitalize;
}

.imageContainer {
  position: relative;
  background-color: @lightGrayLight;
  overflow: auto;
}

.defaultImageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: @lightGrayLight;
  overflow: auto;
}

.damageMapContainer {
  width: 100%;
  height: 100%;
}

.imageDiv {
  max-width: 570px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  :global {
    .ant-upload.ant-upload-drag .ant-upload {
      padding: 0;
    }
    .ant-upload.ant-upload-drag {
      border: none;
      height: initial;
    }
    .ReactCrop {
      max-height: 365px;
      width: 100%;
      height: auto;
    }
  }
}

.damageMapImage {
  width: 100%;
  height: 100%;
}

.thumbnailDiv {
  height: auto;
  overflow: auto;
  margin-top: 40px;
  .thumbnailContainer {
    width: 140px;
    height: 80px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    padding: 0;
  }
}

.thumbnailTitle {
  color: @brandAccentBlue;
  padding-top: 2px;
  padding-bottom: 2px;
}

.thumbnailCategoryDiv {
  margin-bottom: 24px;
}

.thumbnailCategory {
  text-transform: capitalize;
}

.pinDiv {
  position: absolute;
  width: 120px;
  z-index: 10;
}

.defaultImage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.defaultImageMobile {
  display: grid;
  padding-top: 145px;
  .imageDivMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
  }
  .defaultDiv {
    padding-left: 40px;
    .defaultText {
      padding-top: 30px;
    }
  }
}

.uploadIcon {
  margin-right: 8px;
}

.defaultPlaneImage {
  height: 130px;
}

.defaultDiv {
  padding-left: 70px;
  padding-bottom: 60px;
}

.defaultText {
  padding-top: 60px;
  font-weight: bold;
  font-size: 25px;
}

.defaultButton {
  width: 240px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  :global {
    .anticon {
      margin-top: 3px;
    }
  }
}

.defaultParagraph {
  width: 265px;
  margin-bottom: 30px;
}

.schematicsImage {
  padding-bottom: 20px;
  img {
    height: 125px;
  }
}

.defaultUploadText {
  color: @grayBlue;
}

.badge {
  padding-left: 4px;
}

.firstColumn {
  vertical-align: top;
  padding-top: 32px;
  word-break: break-all;
}

.tableColumn {
  word-break: break-all;
}

.buttonsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  .expandIconWrapper {
    z-index: 10;
    margin-top: 22px;
    .expandIcon {
      height: 20px;
      margin-top: -25px;
    }
  }
  .menuDots {
    margin-left: 50%;
  }
  .menuDotsHidden {
    visibility: hidden;
    opacity: 0;
  }
}

.statusBadge {
  :global {
    sup {
      width: 10px;
      height: 10px;
    }
  }
}

.label {
  background-color: @brandLightGray;
  text-transform: capitalize;
  opacity: 0.85;
  margin-bottom: 0;
  padding: 3px;
  border-radius: 25px;
  font-weight: 500;
}

.labelText {
  padding-left: 10px;
  padding-right: 20px;
}

.triangleDown {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid @brandLightGray;
  margin-left: 35px;
}

.highlightedRow {
  background: @aquaExtraLight;
}

.dropdownIcon {
  float: right;
  font-size: 20px;
}

.uploadDiv {
  width: 90%;
  height: 90%;
  position: absolute;
  top: 0;
  left: 0;
}

.uploadDivHidden {
  display: none;
}

.buttonText {
  display: flex;
  align-items: center;
  img {
    width: 12px;
    margin-right: 8px;
  }
}

.addAndDisplayDiv {
  margin-top: 20px;
  width: 100%;
  height: 100%;
  display: grid;
}

.addNewBox {
  width: 130px;
  height: 80px;
  border: 1px dashed @brandAccentBlue;
  display: flex;
  background-color: @white;
  justify-content: center;
  align-items: center;
}

.addNewText {
  margin-top: 5px;
  margin-left: 37px;
}

.plusIcon {
  font-size: 25px;
  color: @grayPlusSign;
}

.titlesGroupDiv {
  display: flex;
  padding-top: 11px;
  padding-bottom: 10px;
}

.titlesGroupInputDiv {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
}

.footerDiv {
  padding: 15px 40px;
}

.footerDivMobile {
  display: none;
}

.uploadImage {
  height: 35px;
}

.titleDiv {
  height: 25px;
  width: 250px;
  display: grid;
  padding-left: 28px;
}

.title {
  width: 240px;
  margin-left: 8px;
  margin-bottom: 4px;
}

.categoryTitle {
  width: 240px;
  margin-left: 8px;
  margin-bottom: 4px;
}

.titleInput {
  width: 240px;
  height: 34px;
  margin-left: 8px;
  border: solid 1px @lightGrayMedium;
  border-radius: 2px;
}

.saveButtonDiv {
  margin-top: 25px;
  margin-left: -4px;
  .saveButton {
    width: 90px;
  }
}

.modalBody {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.thumbnailColumn {
  display: grid;
  grid-template-rows: 140px 1fr;
  padding-left: 20px;
  max-height: 480px;
}

.imageColumn {
  display: grid;
  grid-template-rows: 360px 100px;
  padding-top: 20px;
}

.imageWrapperDiv {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.fileUploadDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 4px;
}

.arrowButton {
  color: @white;
  font-size: 10px;
  background-color: @blueExtraLight;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowButtonClickable {
  color: @white;
  font-size: 10px;
  background-color: @blueMedium;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: @screen-md) {
  .modal {
    :global {
      .ant-modal-body {
        padding: 8px 10px;
      }
      .ant-modal-footer {
        padding: 12px;
      }
    }
  }
  .modalHeaderContainer {
    .subtitleText {
      display: none;
    }
  }
  .modalBodyMobile {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 165px 180px 200px;
    .imageWrapperDiv {
      width: 100%;
      .fileUploadDiv {
        width: 100%;
        margin-right: 4px;
        .arrowButton {
          width: 27px;
        }
        .arrowButtonClickable {
          width: 27px;
        }
        .imageDiv {
          max-width: 570px;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 4px;
          margin: 0;
          width: fit-content;
          :global {
            .ReactCrop {
              max-height: 165px;
              .ReactCrop__image {
                height: auto;
                width: 100%;
              }
            }
            .ant-upload.ant-upload-drag .ant-upload-drag-container {
              padding: 0 !important;
            }
            .ant-upload.ant-upload-drag {
              border: none;
            }
          }
        }
        .dragger {
          width: 100%;
          .uploadButton {
            height: 165px;
            width: 250px;
            font-size: 14px;
            font-weight: 500;
            border: 1px dashed @brandAccentBlue;
            background-color: @white;
            .textFiles {
              font-size: 14px;
            }
            .defaultUploadText {
              font-size: 14px;
            }
          }
        }
      }
    }
    .titlesGroupInputDiv {
      display: grid;
      grid-template-rows: 65px 65px 50px;
      padding: 0;
      .titleDiv {
        width: 100%;
        padding: 0 4px;
        .title {
          margin-left: 0;
        }
        .titleInput {
          margin-left: 0;
          width: 100%;
        }
        .categoryTitle {
          margin-left: 6px;
        }
        .dropdown {
          margin-left: 0;
          width: 100%;
        }
      }
      .saveButtonDiv {
        margin-left: 0;
        margin-top: 10px;
        padding: 0 4px;
        .saveButtonMobile {
          width: 100%;
        }
      }
    }
    .thumbnailColumn {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      padding: 0 4px;
      overflow: auto;
      max-height: auto;
      .addAndDisplayDiv {
        padding: 4px 10px;
        margin-top: 0;
      }
      .statusBadge {
        padding: 4px 10px;
        height: 80px;
        .thumbnailContainer {
          width: 140px;
          padding: 0;
          height: 80px;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          position: relative;
        }
      }
    }
  }
  .footerDiv {
    display: none;
  }
  .footerDivMobile {
    display: grid;
    grid-template-rows: 50px 40px;
    padding: 8px 0;
    .saveAndFinishButton {
      width: 100%;
    }
    .saveAndFinishClickable {
      width: 100%;
    }
    .cancelButton {
      width: 100%;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: @screen-lg) {
  .addNewButton {
    float: right;
    border-radius: 50%;
    padding: 0 8px;
    .buttonText {
      display: none;
    }
  }
}

.modal {
  :global {
    .ant-modal-close:hover {
      background-color: @brandLightGray;
      border-radius: 50%;
    }
    .ant-modal-close {
      .anticon svg {
        font-size: 22px;
        margin-top: 17px;
      }
    }
    .ant-btn {
      border-radius: 2px;
    }
    .ant-modal-body {
      background-color: @lightGrayExtraLight;
    }
  }
}

.closeIconImage {
  margin-left: -3px;
}

.uploadButton {
  height: 340px;
  width: 550px;
  font-size: 20px;
  font-weight: 500;
  border: 1px dashed @brandAccentBlue;
  background-color: @white;
}

.clickHere {
  color: @brandAccentBlue;
}

.saveButton {
  width: 86px;
}

.thumbnailWrapperDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .listImageDiv {
    width: 90px;
    height: 60px;
    position: relative;
  }
  .extraThumbs {
    display: inline;
    padding-left: 32px;
  }
  .extraThumbsHidden {
    display: none;
  }
}

.listThumbnailImage {
  width: 115px;
  height: 80px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  border: 1px solid @lightGrayLight;
  cursor: pointer;
}

.listThumbnailImageEmpty {
  background-color: @white;
}

.listThumbnail {
  max-height: 100%;
  width: 100%;
  position: absolute;
}

.thumbnailPreviewModal {
  width: 1000px !important;
  :global {
    .ant-modal-body {
      background-color: @offWhite;
      padding: 48px 24px;
      border-radius: 0% 0% 4px 4px;
    }
  }
}

@media screen and (max-width: @screen-lg) {
  .modal {
    width: 800px !important;
  }
  .modalBody {
    .imageColumn {
      .uploadButton {
        height: 315px;
        width: 415px;
      }
    }
    .titlesGroupInputDiv {
      padding-left: 44px;
      .titleDiv {
        width: 150px;
        padding-left: 27px;
      }
      .title {
        width: 150px;
      }
      .titleInput {
        width: 150px;
      }
      .saveButtonDiv {
        margin-left: 62px;
      }
      .categoryTitle {
        width: 150px;
        padding-left: 6px;
      }
      .dropdown {
        width: 150px;
        padding-left: 6px;
      }
    }
  }
  .selectorButtons {
    display: none;
  }

  .selectorDropdown {
    display: block;
    padding: 20px 0;
    .dropdownTitle {
      font-weight: 500;
    }
  }
}

.thumbnailModal {
  display: flex;
  .thumbnailDiv {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    margin-top: 0;
    height: auto;
    overflow: auto;
    width: 20%;
    .thumbnailColumnDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      :hover {
        box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.01), 0 1px 7px 0 rgba(0, 0, 0, 0.15);
      }
      .thumbnailWrapper {
        width: 115px;
        height: 80px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        margin: 4px 0;
        display: flex;
        border: 1px solid @borderGray;
        background-color: @white;
        cursor: pointer;
        .thumbnailItem {
          margin: auto;
          justify-content: center;
          text-align: center;
          cursor: pointer;
        }
        .thumbnailImage {
          width: auto;
          height: stretch;
        }
      }
    }
  }
  .imageDivWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    .arrowDiv {
      padding: 10px;
    }
    .displayDiv {
      display: flex;
      padding: 20px 0;
      justify-content: center;
      background-color: @lightGrayExtraLight;
      img {
        max-width: 100%;
        max-height: 60vh;
      }
    }
  }
}

@media screen and (max-width: @screen-lg) {
  .thumbnailPreviewModal {
    :global {
      .ant-modal-body {
        padding: 20px 24px;
      }
    }
  }
}

.thumbnailModalMobile {
  display: flex;
  .thumbnailDiv {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    margin-top: 0;
    height: auto;
    overflow: auto;
    width: 20%;
    .thumbnailColumnDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      :hover {
        box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.01), 0 1px 7px 0 rgba(0, 0, 0, 0.15);
      }
      .thumbnailWrapper {
        width: 65px;
        height: 45px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        margin: 4px 0;
        display: flex;
        border: 1px solid @borderGray;
        background-color: @white;
        padding: 4px 0;
        cursor: pointer;
      }
    }
  }
  .imageDivWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    .arrowDiv {
      padding: 10px;
      .arrowButton,
      .arrowButtonClickable {
        height: 20px;
        width: 20px;
      }
    }
    .displayDiv {
      display: flex;
      padding: 20px 0;
      justify-content: center;
      background-color: @lightGrayExtraLight;
      img {
        max-width: 100%;
        max-height: 60vh;
      }
    }
  }
}

:global(.ant-modal-header) {
  padding-bottom: 16px;
  padding-top: 16px;
}

:global(.ant-modal-footer) {
  padding-top: 20px;
  padding-bottom: 20px;
}

.modalHeaderContainer {
  .titleContainer {
    .titleTopContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .headerGroup {
        vertical-align: middle;
        text-align: left;
        margin-top: auto;
        margin-bottom: auto;
        .titleText {
          font-size: 18px;
          img {
            margin-right: 6px;
          }
        }
        .subtitleText {
          font-size: 14px;
          color: @blackMedium;
          font-weight: normal;
          line-height: normal;
        }
      }
      .closeIcon {
        text-align: center;
      }
      .closeIcon:hover {
        cursor: pointer;
      }
    }
  }
}

.modalSubtitleContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  .modalTitleSub {
    font-size: 14px;
    color: @gray;
  }
}

.spinDiv {
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: @screen-sm) {
  .pageWrapper {
    padding: 0 4px;
    .pageTitle {
      padding-left: 20px;
    }
  }
}
