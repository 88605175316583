@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';
/* stylelint-disable */

.reference {
  font-weight: 500;
}

.dropdownIcon {
  font-size: 20px;
  padding-right: 5px;
  color: @brandAccentBlue;
  display: flex;
  align-items: center;
}

.defaultTable {
  td {
    word-break: break-all;
  }
}

.tableWrapper {
  max-height: calc(100vh - 290px);
  overflow-y: auto;
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
  svg {
    padding-top: 0;
  }
}

.intermittentFaultsCard {
  background-color: #fafafa;
  border: 1px solid @white;
  border-radius: 3px;
  :global {
    .ant-card-body {
      padding: 1.5rem;
    }
  }
}

.modalContentWrapper {
  height: auto;
  .modalTitle {
    font-weight: 500;
    color: #242d41;
    padding-bottom: 12px;
  }
  .modalMessage {
    line-height: 1.29;
    color: rgba(36, 45, 65, 0.7);
  }
  .modalButtonWrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: 28px;
    .submitButton {
      margin-right: 12px;
    }
    .cancelButton {
      border-radius: 16px;
    }
  }
}

.emptyStateDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 24px;
  img {
    width: 150px;
  }
  span {
    font-size: 13px;
    color: rgba(36, 45, 65, 0.5);
    letter-spacing: 0.42px;
  }
}

.menuButton {
  cursor: pointer;
}

.statusWrapper {
  display: flex;
  align-items: center;
}

.buttonText {
  display: flex;
  align-items: center;
  img {
    width: 12px;
    margin-right: 8px;
  }
}

.emptyText {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  img {
    height: 115px;
  }
  span {
    color: rgba(36, 45, 65, 0.5);
    font-size: 13px;
    padding-top: 8px;
    letter-spacing: 0.42px;
  }
}

.tableHeader {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.tableHeader :nth-child(1) {
  flex: 1;
}

.tableTitle {
  font-size: 16px;
  font-weight: 500;
}

.tableHeader input {
  box-sizing: content-box;
}

.filterIcon,
.filterButton {
  margin-left: 0.5em;
  margin-right: 2em;
  height: 1em;
  width: 1em;
}

.filterButton {
  border: none;
  background-color: transparent;
  display: none;
}

.drawerHeader {
  margin: 12px 0 0;
  padding-left: 26px;
  padding-bottom: 1em;
  border-bottom: 1px solid @borderGrey;
  span {
    font-weight: 600;
    font-size: 16px;
  }
}

.searchInput {
  float: right;
  margin-bottom: 1em;
}

.threeDotMenu {
  height: 17px;
  cursor: pointer;
}

.pageContentWrapper {
  display: flex;
  .ant-layout,
  .ant-layout * {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  .mainCol {
    flex: 1;
    padding: 28px 0 28px 28px;
    .tableWrapper {
      overflow: auto;
      width: calc(100vw - 730px);
    }
    .tableWrapperCollapsedMenu {
      overflow: auto;
      width: calc(100vw - 590px);
    }
  }

  .filtersCol {
    width: 220px;
    flex-shrink: 0;
    margin-left: 28px;
    -webkit-box-sizing: unset;
    box-sizing: unset;
  }
  .filtersCol > div:first-child {
    height: calc(100% - 40px);
  }
}

.dropdownMenu {
  padding: 0;
  :global {
    .ant-dropdown-menu-item {
      padding: 0;
    }
    .ant-dropdown-menu-item:hover {
      background-color: transparent;
    }
  }
}

.dropdownItem {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #fcfcfc;
  &:hover {
    background-color: rgba(18, 111, 214, 0.05);
    color: @brandAccentBlue;
  }
  &:focus {
    border: 1px solid @brandAccentBlue;
    border-radius: 4px 4px 0 0;
    color: @brandAccentBlue;
  }
}
.deleteItem {
  color: #ca2322;
  background-color: rgba(202, 35, 34, 0.05);
  &:hover {
    background-color: rgba(202, 35, 34, 0.1);
    color: #ca2322;
  }
  &:focus {
    background-color: rgba(202, 35, 34, 0.05);
    border: 1px solid #ca2322;
    border-radius: 0 0 4px 4px;
    color: #ca2322;
  }
}

.tableActions {
  display: flex;
  gap: 1rem;
}

.workpacksTable {
  :global {
    .ant-table-wrapper {
      table {
        border-collapse: separate;
        border-spacing: 0 1em;
      }
    }

    .ant-table-thead {
      tr > th {
        background-color: transparent;
        text-transform: uppercase;
        padding: 1em;
        color: #c8c8c8;
        font-size: 0.8em;
        border: none;
      }
    }

    .ant-table-tbody {
      tr {
        background-color: @white;
      }

      td {
        border: none;
        padding: 0.5em 1em;
      }

      td:first-child {
        border-top-left-radius: 0.35em;
        border-bottom-left-radius: 0.35em;
        padding-right: 0;
      }

      td:last-child {
        border-top-right-radius: 0.35em;
        border-bottom-right-radius: 0.35em;
        padding-left: 0;
      }
    }
  }
}

@media (max-width: 1000px) {
  .tableWrapperCollapsedMenu {
    width: calc(100vw - 367px) !important;
  }
  .tableWrapper {
    width: calc(100vw - 507px) !important;
  }
  .pageContentWrapper {
    .filtersCol {
      display: none;
    }
  }
  .filterButton {
    display: block;
  }
}

@media screen and (max-width: 800px) {
  .addNewButton {
    width: auto;
    margin-bottom: 8px;
    float: right;
    z-index: 5;
    border-radius: 50%;
    padding: 0 8px;
    .buttonText {
      display: none;
    }
  }
}

@media (max-width: 450px) {
  .pageContentWrapper {
    .mainCol {
      padding: 0 4px;
    }
  }
  .tableHeader {
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .tableActions {
    display: flex;
    justify-content: space-between;
  }
  .tableWrapper {
    width: calc(100vw - 10px) !important;
  }
  .tableWrapperCollapsedMenu {
    width: calc(100vw - 10px) !important;
  }
  .intermittentFaultsCard {
    :global {
      .ant-card-body {
        padding: 1rem;
      }
    }
  }
}
