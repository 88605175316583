@import '../../utils/utils.less';

.drawerBg,
.drawerBgBanVis {
  background-color: rgba(3, 44, 82, 0.33);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  z-index: 200;
}

.drawerBgBanVis {
  top: 56px;
  height: calc(100vh - 56px);
}

.drawerWrapper {
  width: 0;
  height: calc(100vh - 24px);
  right: 0;
  top: 12px;
  background-color: #fff;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 201;
  .childrenOuterWrapper {
    height: 100%;
    .childrenWrapper {
      height: 100%;
    }
  }
}

.drawerWrapperBanVis {
  height: calc(100vh - 80px);
  width: 0;
  right: 0;
  top: 12px;
  background-color: #fff;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 201;
  .childrenOuterWrapper {
    height: 100%;
    .childrenWrapper {
      height: 100%;
    }
  }
}

.drawerSlideOut {
  height: calc(100vh - 24px);
  width: 776px;
  background-color: @thinGray;
  border-radius: 5px 0 0 5px;
  animation: slideOut 0.3s;
}

.drawerSlideOutBanVis {
  height: calc(100vh - 80px);
  width: 776px;
  background-color: @thinGray;
  border-radius: 5px 0 0 5px;
  animation: slideOut 0.3s;
}

.drawerAlreadyOut {
  height: calc(100vh - 24px);
  width: 776px;
  background-color: @thinGray;
  border-radius: 5px 0 0 5px;
}

.drawerSlideIn {
  height: calc(100vh - 24px);
  width: 776px;
  background-color: @thinGray;
  border-radius: 5px 0 0 5px;
  animation: slideIn 0.3s;
}

.drawerSlideInBanVis {
  height: calc(100vh - 80px);
  width: 776px;
  background-color: @thinGray;
  border-radius: 5px 0 0 5px;
  animation: slideIn 0.3s;
}

.drawerSlideOutFilters {
  height: calc(100vh - 24px);
  width: 240px;
  background-color: @thinGray;
  border-radius: 5px 0 0 5px;
  animation: slideOut 0.3s;
}

.drawerSlideOutFiltersBanVis {
  height: calc(100vh - 80px);
  width: 240px;
  background-color: @thinGray;
  border-radius: 5px 0 0 5px;
  animation: slideOut 0.3s;
}

.drawerSlideInFilters {
  height: calc(100vh - 24px);
  width: 240px;
  background-color: @thinGray;
  border-radius: 5px 0 0 5px;
  animation: slideIn 0.3s;
}

.drawerSlideInFiltersBanVis {
  height: calc(100vh - 80px);
  width: 240px;
  background-color: @thinGray;
  border-radius: 5px 0 0 5px;
  animation: slideIn 0.3s;
}

.bgFadeOut {
  animation: fadeBg 0.3s;
}

.drawerCloseButton,
.drawerClosrButtonFilters {
  cursor: pointer;
  position: absolute;
  top: 33px;
  right: 35px;
  z-index: 201;
  border: none;
  background: transparent;
  .closeIcon {
    height: 12px;
    width: 12px;
  }
}

.drawerClosrButtonFilters {
  top: 12px;
  right: 12px;
}

@keyframes slideOut {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes fadeBg {
  0% {
    background-color: rgba(50, 50, 50, 0.5);
  }
  100% {
    background-color: rgba(50, 50, 50, 0);
  }
}

@media screen and (max-width: 770px) {
  .drawerSlideOut {
    width: 768px;
  }
  .drawerSlideIn {
    width: 768px;
  }
}

@media screen and (max-width: 450px) {
  .drawerSlideOut {
    width: 100vw;
  }
  .drawerSlideIn {
    width: 100vw;
  }
}
