@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.booleanFilter {
  padding: 10px 26px 10px 26px;
}

.layoutDiv {
  width: 100%;
  display: flex;
  .mainCol {
    width: 100%;
    height: auto;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 1000px;
  }
  .filtersCol {
    width: 250px;
    float: right;
    margin-top: -22px;
    margin-bottom: -24px;
    margin-left: 30px;
    .innerScroll {
      overflow-y: auto;
      max-height: 85vh;
    }
  }
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.mobileWrapper {
  display: flex;
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
  svg {
    padding-top: 0;
  }
}

.buttonsWrapper {
  display: flex;
  margin-left: 20px;
}

.addButton {
  flex-shrink: 0;
  img {
    margin-right: 8px;
  }
}

.filterButton {
  border: none;
  background-color: transparent;
  display: none;
  margin-left: 4px;
  img {
    width: 14px;
    height: 14px;
  }
}

.drawerHeader {
  margin: 12px 0 0;
  padding-left: 26px;
  padding-bottom: 1em;
  border-bottom: 1px solid @borderGrey;
  span {
    font-weight: 600;
    font-size: 16px;
  }
}

.bottomBoundary {
  width: 100%;
  height: 1px;
}

.stickyFilters {
  height: 100%;
}

.pageTitle {
  font-size: 16px;
  font-weight: 500;
}

.filtersCol {
  margin-left: 20px;
  padding-top: 20px;
  background-color: #fcfcfc;
  box-shadow: -2px 0 5px 0 rgba(230, 232, 237, 0.5);

  :global {
    .ant-btn > .anticon + span,
    .ant-btn > span + .anticon {
      margin-left: 0;
    }
  }
}

.buttonText {
  display: flex;
  align-items: center;
  img {
    width: 12px;
    margin-right: 8px;
  }
}

.spacer {
  height: 26px;
}

.overCardTitle {
  margin: 0;
}

.mxListCol,
.mxItemsImporter {
  margin-bottom: 26px;
}

.mxListCol > .periodTitle ~ .periodTitle {
  margin-top: 12px;
}

.filtersDrawerWrapper {
  display: none;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  :global {
    .ant-layout,
    .ant-layout * {
      -webkit-box-sizing: inherit;
      box-sizing: inherit;
    }
  }
  .overCardTitle {
    margin-right: 20px;
  }
  .syncButtonWrapper {
    margin-bottom: 15px;
    min-width: 250px;
    button,
    .syncButton {
      cursor: pointer;
      margin-right: 5px;
      border-radius: 50px;
      width: 24px;
      height: 24px;
      background-color: @brandAccentBlue;
    }
    .syncButton {
      width: auto;
      color: @white;
      padding: 0 10px;
    }
  }
}

@media screen and (max-width: @screen-lg) {
  // .buttonText {
  //   display: none !important; // overriding antd styles
  // }
  .filtersCol {
    display: none;
  }
  .filterButton {
    display: block;
  }
  .filtersDrawerWrapper {
    width: 30%;
    display: inline-block;
    text-align: right;
    button {
      position: relative;
      top: 3px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .headerWrapper {
    flex-direction: column;
    :global {
      #searchWrapper {
        margin-right: 8px;
      }
    }
    .mobileWrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 15px;
    }
  }
  .titleWrapper {
    width: 100%;
  }
}

@media screen and (max-width: @screen-sm) {
  // .buttonText {
  //   display: none !important; // overriding antd styles
  // }
  .pageCard {
    :global {
      .ant-card-body {
        padding: 0 4px;
      }
    }
  }
  .headerWrapper {
    flex-direction: column;
    padding: 24px 20px 15px;
    :global {
      #searchWrapper {
        margin-right: 8px;
      }
    }
    .mobileWrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 16px;
    }
  }

  .mxListCol {
    padding: 0 20px;
  }
}
