.iconTrayWrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: flex-end;
  width: 100%;
  img {
    margin-right: 4px;
    width: 24px;
  }
}

.attachmentsWrapper {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  &:focus {
    outline: none;
  }
  img {
    margin-right: 4px;
    width: 16px;
  }
}