@import '~antd/lib/style/themes/default.less';

.datePicker {
  width: 200px;
  height: 40px;
  display: block;
  :global {
    .ant-input {
      width: 200px;
      height: 40px;
      border-radius: 2px;
      border: solid 1px rgba(36, 45, 65, 0.1);
      background-color: #f3f7fc;
    }
  }
  div {
    height: 100%;
  }
}

.datePickerSmall {
  width: 140px;
  height: 40px;
  :global {
    .ant-input {
      width: 140px;
      height: 40px;
      border-radius: 2px;
      border: solid 1px rgba(36, 45, 65, 0.1);
      background-color: #f3f7fc;
    }
  }
  div {
    height: 100%;
  }
}

.timePicker {
  height: 40px;
  :global {
    .ant-time-picker-input {
      height: 40px;
      border-radius: 2px;
      border: solid 1px rgba(36, 45, 65, 0.1);
      background-color: #f3f7fc;
    }
  }
}

@media screen and (max-width: 450px) {
  .datePicker {
    :global {
      .ant-input {
        width: 140px;
        border-radius: 2px;
        border: solid 1px rgba(36, 45, 65, 0.1);
        background-color: #f3f7fc;
      }
    }
  }
}
